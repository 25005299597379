<template>
    <div>
        <h3>Template Editor</h3>
        <hr>
        <template-list></template-list>
        <hr>
        <template-schedule></template-schedule>
    </div>
</template>

<script>
import TemplateList from "@/components/AdminModules/General/TemplateList";
import TemplateSchedule from "@/components/AdminModules/General/TemplateSchedule";

export default {
    name: "TemplateEditor",
    components: {
        TemplateList,
        TemplateSchedule
    },
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    },
    methods: {

    },
    computed: {

    }
}
</script>

<style scoped>

</style>
