<template>
    <div>
        <h3>Schedule - {{curSchedName}}</h3>
        <hr>
        <b-button @click="addSchedule" variant="outline-success">
            <b-icon icon="calendar-plus"></b-icon> Add
        </b-button>
        <p></p>
        <div class="text-center mb-3"
             style="max-width: 60rem"
        >
            <b-table striped hover responsive="true" :items="items" :fields="fields">
                <template #cell(status)="row">
                    <b-icon-cloud-check v-if="items[row.index].stat"></b-icon-cloud-check>
                    <b-icon-cloud-slash v-else></b-icon-cloud-slash>
                </template>
                <template #cell(edit)="row">
                    <b-icon-pencil-fill @click="editItem(row.index)"></b-icon-pencil-fill>
                </template>
                <template #cell(duplicate)="row">
                    <b-icon-stickies-fill @click="dupItem(row.index)"></b-icon-stickies-fill>
                </template>
                <template #cell(delete)="row">
                    <b-icon-trash-fill @click="delItem(row.index)"></b-icon-trash-fill>
                </template>
            </b-table>
            <hr>
            <b-button @click="saveSchedule" variant="outline-primary">
                <b-icon icon="cloud-arrow-up"></b-icon> Save
            </b-button>
            <b-modal
                id="edit-sched"
                ref="modal"
                centered
                style="min-width: 30rem"
                :title="addEditTitle"
                @show="resetEditModal"
                @hidden="resetEditModal"
                @ok="handleEditOk"
            >
                <template #modal-footer>
                    <b-container>
                        <b-row cols="5">
                            <b-col cols=""></b-col>
                            <b-button
                                variant="success"
                                @click="handleEditOk"
                            >
                                Save
                            </b-button>
                            <b-col></b-col>
                            <b-button
                                variant="danger"
                                @click="handleEditCancel"
                            >
                                Cancel
                            </b-button>
                        </b-row>
                    </b-container>
                </template>
                <b-form ref="form" class="text-center" @submit.stop.prevent="handleEditSubmit">
                    <b-form-group>
                        <b-form-checkbox-group
                            v-model="selDays"
                            :options="dayChecks"
                            class="mb-3"
                            value-field="item"
                            text-field="name">
                        </b-form-checkbox-group>
                        <b-container>
                            <b-row cols="3">
                                <b-col>
                                    <b-button @click="freqPreset(1)">Everyday</b-button>
                                </b-col>
                                <b-col>
                                    <b-button @click="freqPreset(2)">Weekdays</b-button>
                                </b-col>
                                <b-col>
                                    <b-button @click="freqPreset(3)">Weekends</b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                        <hr>
                        <b-container>
                            <b-row cols="6">
                                <b-col>
                                    <label style="max-width: 5rem">When:</label>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-select v-model="selWhen" v-on:change="onWhenChange" :options="whenList"></b-form-select>
                                </b-col>
                                <b-col cols="5" v-if="selWhen===2">
                                    <b-form-input id="type-time" type="time" v-model="whenDate"></b-form-input>
                                </b-col>
                                <b-col cols="2" v-if="selWhen===3">
                                    <label>Every: </label>
                                </b-col>
                                <b-col v-if="selWhen===3">
                                    <b-form-input v-model="intTime"></b-form-input>
                                </b-col>
                                <b-col v-if="selWhen===3">
                                    <label>Min(s)</label>
                                </b-col>

                            </b-row>
                        </b-container>
                        <hr>
                        <b-container>
                            <b-row cols="6">
                                <b-col>
                                    <label>Offset:</label>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-select :disabled="offDisable" v-model="selOffset" :options="offList"></b-form-select>
                                </b-col>
                                <b-col v-if="selOffset > 0">
                                    <b-form-input v-model="offsetTime"></b-form-input>
                                </b-col>
                                <b-col v-if="selOffset > 0">
                                    <label>Min(s)</label>
                                </b-col>
                            </b-row>
                        </b-container>
                        <hr>
                        <b-container>
                            <b-row cols="3">
                                <b-col>
                                    <label>Duration:</label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model="durTime"></b-form-input>
                                </b-col>
                                <b-col>
                                    <label>Sec(s)</label>
                                </b-col>
                            </b-row>
                        </b-container>
                        <div v-if="numZones===2">
                            <hr>
                            <b-form-checkbox-group
                                v-model="selZones"
                                :options="zoneChecks"
                                value-field="item"
                                text-field="name">
                            </b-form-checkbox-group>
                        </div>
                    </b-form-group>
                </b-form>
            </b-modal>
        </div>
    </div>
</template>

<script>
import eTypes from "@/enumTypes";
import {EventBus} from "@/plugins/event-bus";

export default {
    name: "TemplateSchedule",
    data() {
        return {
            devId: null,
            items: [],
            fields: [
                'status',
                'frequency',
                'offset',
                'when',
                'duration',
                'zone',
                'edit',
                'duplicate',
                'delete'
            ],
            selItem: 0,
            addEdit: 0,
            addEditTitle: "Add",
            selDays: [],
            dayChecks: [
                {item: 0, name: "Su"},
                {item: 1, name: "Mo"},
                {item: 2, name: "Tu"},
                {item: 3, name: "We"},
                {item: 4, name: "Th"},
                {item: 5, name: "Fr"},
                {item: 6, name: "Sa"}
            ],
            selZones: [0],
            zoneChecks: [],
            selWhen: 0,
            whenList: [
                {value: 0, text: "Sunrise"},
                {value: 1, text: "Sunset"},
                {value: 2, text: "Custom"},
                {value: 3, text: "Interval"}
            ],
            whenDate: '12:00',
            intTime: 45,
            selOffset: 0,
            offList: [
                {value: 0, text: "At"},
                {value: 1, text: "Before"},
                {value: 2, text: "After"},
            ],
            offDisable: null,
            offsetTime: 30,
            durTime: 25,
            numZones: 1,
            schedRef: []
        }
    },
    created() {
        // eslint-disable-next-line no-prototype-builtins
        if (this.$store.getters.selectedTemplates.hasOwnProperty('sch')) {
            if (this.$store.getters.selectedTemplates.sch.length > 0) {
                this.schedRef = JSON.parse(JSON.stringify(this.$store.getters.selectedTemplates.sch));
            }
        } else {
            this.schedRef = [];
        }
        this.zoneChecks = [];
        this.zoneChecks.push({item: 0, name: "Zone 1", disabled: true });
        this.numZones = 2;
        if (this.numZones === 2) {
            this.zoneChecks.push({item: 1, name: "Zone 2", disabled: false });
        }
        this.updateSched();
    },
    mounted() {
        EventBus.$on("refreshSched", () => {
            // eslint-disable-next-line no-prototype-builtins
            if (this.$store.getters.selectedTemplates !== undefined) {
                if (this.$store.getters.selectedTemplates.sch.length > 0) {
                    this.schedRef = JSON.parse(JSON.stringify(this.$store.getters.selectedTemplates.sch));
                    this.updateSched();
                } else {
                    this.schedRef = [];
                    this.items = [];
                }
            }
            else {
                this.schedRef = [];
                this.items = [];
            }
        });
    },
    beforeDestroy() {
        EventBus.$off("refreshSched");
    },
    methods: {
        saveSchedule() {
            let sch = [];
            for (let i = 0; i < this.schedRef.length; i++) {
                sch.push({
                    time: this.schedRef[i].time,
                    freq: this.schedRef[i].freq,
                    eType: this.schedRef[i].eType,
                    duration: this.schedRef[i].duration,
                    interval: this.schedRef[i].interval,
                    gmtOffset: this.schedRef[i].gmtOffset,
                    startOffset: this.schedRef[i].startOffset,
                    startFlag: this.schedRef[i].startFlag,
                    daysFlag: this.schedRef[i].daysFlag,
                    zone: this.schedRef[i].zone,
                });
            }
            let schRef = this.$store.getters.selectedTemplates;
            schRef.sch = JSON.parse(JSON.stringify(sch));
            //console.log(schRef);
            this.$store.dispatch('saveTemplate', schRef).then(() => {
                this.$store.dispatch('getTemplateSchedules').then(() => {
                    this.$bvToast.toast('Template Saved', {
                        title: 'Template Save',
                        variant: 'success',
                        solid: true
                    });
                    this.updateSched();
                }).catch(() => {

                });
            }).catch(() => {
                this.$bvToast.toast('Error Saving Template', {
                    title: 'Template Save',
                    variant: 'success',
                    solid: true
                });
            });
        },
        addSchedule() {
            this.addEdit = 0;
            this.selDays = [0, 1, 2, 3, 4, 5, 6];
            this.addEditTitle = "Add";
            this.$refs['modal'].show();
        },
        onWhenChange() {
            if (this.selWhen > 1) {
                this.selOffset = 0;
                this.offDisable = true;
            } else {
                this.offDisable = null;
            }
        },
        freqPreset(presetNum) {
            if (presetNum === 1) {
                this.selDays = [0, 1, 2, 3, 4, 5, 6];
            }
            else if (presetNum === 2) {
                this.selDays = [1, 2, 3, 4, 5];
            }
            else if (presetNum === 3) {
                this.selDays = [0,6];
            }
        },
        editItem(itemIndex) {
            //console.log(itemIndex);
            this.selItem = itemIndex;
            this.addEdit = 1;
            this.addEditTitle = "Edit";
            const curSch = this.schedRef[this.selItem];
            this.selDays = [];
            if ((curSch.daysFlag & eTypes.enums.Days.SUNDAY) === eTypes.enums.Days.SUNDAY)
                this.selDays.push(0);
            if ((curSch.daysFlag & eTypes.enums.Days.MONDAY) === eTypes.enums.Days.MONDAY)
                this.selDays.push(1);
            if ((curSch.daysFlag & eTypes.enums.Days.TUESDAY) === eTypes.enums.Days.TUESDAY)
                this.selDays.push(2);
            if ((curSch.daysFlag & eTypes.enums.Days.WEDNESDAY) === eTypes.enums.Days.WEDNESDAY)
                this.selDays.push(3);
            if ((curSch.daysFlag & eTypes.enums.Days.THURSDAY) === eTypes.enums.Days.THURSDAY)
                this.selDays.push(4);
            if ((curSch.daysFlag & eTypes.enums.Days.FRIDAY) === eTypes.enums.Days.FRIDAY)
                this.selDays.push(5);
            if ((curSch.daysFlag & eTypes.enums.Days.SATURDAY) === eTypes.enums.Days.SATURDAY)
                this.selDays.push(6);

            this.selOffset = 0;
            this.offDisable = null;
            if (curSch.startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CUSTOM) {
                this.whenDate = this.moment(this.getLocalOffset(curSch.time, curSch.gmtOffset) * 1000).format("HH:mm");
                this.offDisable = true;
                this.selWhen = 2;
            }
            else if (curSch.startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_INTERVAL) {
                this.selWhen = 3;
                this.intTime = curSch.interval;
            }
            else if (curSch.startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNRISE) {
                this.selWhen = 0
            }
            else if (curSch.startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNSET) {
                this.selWhen = 1;
            }
            if (curSch.startOffset !== 0) {
                if (curSch.startOffset < 0) {
                    this.selOffset = 1;
                    this.offsetTime = Math.abs(curSch.startOffset);
                }
                else {
                    this.selOffset = 2;
                    this.offsetTime = curSch.startOffset;
                }
            }
            this.durTime = curSch.duration;
            this.$refs['modal'].show();
        },
        dupItem(itemIndex) {
            let dupEntry = {
                time: this.schedRef[itemIndex].time,
                freq: this.schedRef[itemIndex].freq,
                eType: this.schedRef[itemIndex].eType,
                duration: this.schedRef[itemIndex].duration,
                interval: this.schedRef[itemIndex].interval,
                gmtOffset: this.schedRef[itemIndex].gmtOffset,
                startOffset: this.schedRef[itemIndex].startOffset,
                startFlag: this.schedRef[itemIndex].startFlag,
                daysFlag: this.schedRef[itemIndex].daysFlag,
                zone: this.schedRef[itemIndex].zone,
                stat: false
            }
            this.schedRef.push(dupEntry);
            this.updateSched();
        },
        delItem(itemIndex) {
            this.schedRef.splice(itemIndex, 1);
            this.updateSched();
        },
        getLocalOffset(timestamp, altOffset) {
            let offset = new Date().getTimezoneOffset();
            offset = (offset / 60) * -1;
            let totalOffset = offset;
            if (altOffset !== 0) {
                if (altOffset !== offset) {
                    totalOffset = offset - (offset - altOffset);
                }
            }
            let offsetTimestamp = timestamp + (totalOffset * 3600);
            offsetTimestamp += Math.abs(offset) * 3600;
            return offsetTimestamp;
        },
        resetEditModal() {

        },
        // eslint-disable-next-line no-unused-vars
        handleEditOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleEditSubmit();
        },
        // eslint-disable-next-line no-unused-vars
        handleEditCancel(bvModalEvt) {
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('edit-sched')
            });
        },
        handleEditSubmit() {
            let offset = new Date().getTimezoneOffset();
            const sysGmtOffset = (offset / 60) * -1;
            let editIdx = this.selItem;
            if (this.addEdit === 0) { // Add new Entry
                let newEntry = {
                    time: Date.now() / 1000,
                    freq: 4,
                    eType: 1,
                    duration: 60,
                    interval: 45,
                    gmtOffset: sysGmtOffset,
                    startOffset: 0,
                    startFlag: 2,
                    daysFlag: 127,
                    zone: 1
                }
                this.schedRef.push(newEntry);
                editIdx = this.schedRef.length - 1;
            }
            this.schedRef[editIdx].stat = false;
            this.schedRef[editIdx].gmtOffset = sysGmtOffset;
            let daysFlag = 0;
            for (let i = 0; i < this.selDays.length; i++) {
                daysFlag += (1 << this.selDays[i]);
            }
            this.schedRef[editIdx].daysFlag = daysFlag;
            if (this.selOffset !== 0) {
                if (this.selOffset === 1) {
                    this.schedRef[editIdx].startOffset = -this.offsetTime;
                } else {
                    this.schedRef[editIdx].startOffset = this.offsetTime;
                }
            }

            if (this.selWhen === 0) { // Sunrise
                this.schedRef[editIdx].time = Date.now();
                this.schedRef[editIdx].startFlag = 0
            }
            else if (this.selWhen === 1) { // Sunset
                this.schedRef[editIdx].time = Date.now();
                this.schedRef[editIdx].startFlag = 1
            }
            else if (this.selWhen === 2) { // Custom
                this.schedRef[editIdx].time = parseInt(this.moment(this.whenDate, 'HH:mm').format('X'));
                this.schedRef[editIdx].startOffset = 0;
                this.schedRef[editIdx].startFlag = 2
            }
            else if (this.selWhen === 3) { // Interval
                this.schedRef[editIdx].interval = this.intTime;
                this.schedRef[editIdx].startOffset = 0;
                this.schedRef[editIdx].startFlag = 3
            }

            this.schedRef[editIdx].duration = this.durTime;
            this.updateSched();

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('edit-sched')
            });
        },
        updateSched() {
            this.items = [];
            const sch = this.schedRef;
            //console.log('updateSched ' + JSON.stringify(sch));
            for (let i = 0; i < sch.length; i++) {
                let sItem = {};
                if (sch[i].daysFlag === eTypes.enums.Days.WEEKENDS_ONLY) {
                    sItem.frequency = "Weekends";
                }
                else if (sch[i].daysFlag === eTypes.enums.Days.WEEKDAYS_ONLY) {
                    sItem.frequency = "Weekdays";
                }
                else if (sch[i].daysFlag === eTypes.enums.Days.EVERYDAY) {
                    sItem.frequency = "Everyday";
                }
                else {
                    sItem.frequency = "Custom";
                }
                if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CUSTOM) {
                    sItem.when = this.moment(this.getLocalOffset(sch[i].time, sch[i].gmtOffset) * 1000).format("h:mm A");
                }
                else if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_INTERVAL) {
                    sItem.when = "Int " + sch[i].interval + " Mins";
                }
                else if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNRISE) {
                    sItem.when = "Sunrise";
                }
                else if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNSET) {
                    sItem.when = "Sunset";
                }

                // eslint-disable-next-line no-prototype-builtins
                if (sch[i].hasOwnProperty('stat')) {
                    sItem.stat = sch[i].stat;
                } else {
                    sItem.stat = true;
                }

                if (sch[i].startOffset !== 0) {
                    if (sch[i].startOffset > 0) {
                        sItem.offset = sch[i].startOffset + " min after";
                    } else {
                        sItem.offset = -sch[i].startOffset + " min before";
                    }
                } else {
                    sItem.offset = "At";
                }

                sItem.duration = sch[i].duration + " sec";
                sItem.zone = sch[i].zone;

                this.items.push(sItem);
            }
        }
    },
    computed: {
        curSchedName() {
            if (this.$store.getters.selectedTemplates !== undefined) {
                return this.$store.getters.selectedTemplates.name;
            }
            else
                return 'Not Selected';
        }
    }
}
</script>

<style scoped>

</style>
